import React from "react"
import {useSelector, useDispatch} from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Card from "../components/card/card"
import LoanCalculator from "../components/loanCalculator/loanCalculator"
import UniclickForm from "../components/uniclickForm/uniclickForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions";

const tcPdf = <IFrame file="../../TC.pdf" title="Terms of use"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Privacy policy"/>;
const footerDesc = "<p>El financiamiento ofrecido en este sitio web es proveído  por UNIFIN SOFOM la cual se encuentra regulada y supervisada por la CNBV y la CONDUSEF. EVVA Plataforma, S.A.P.I. de C.V. es el intermediario tecnológico entre el usuario del sitio web y la institución financiera, por lo que no realiza actividades financieras o actividades que se encuentre reguladas o supervisadas por autoridad alguna.</p>";
const contentMaxWidth = "980px";

const Financing = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const uniclickFormHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });

        const modal = {
            display: true,
            content: <UniclickForm lang={appState.lang} title="Boost your business now 🚀!" subtitle="For now,  our financing is by invitation only. Register and request your interest as soon as possible." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpUniclickCreated"/>
        };

        dispatch(ACTIONS.showModal(modal))
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const rangeHandler = (e) => {
        const {name, value} = e.target;

        if (name === "amount") {
            dispatch(ACTIONS.setLoanValue(value))
        }

        if (name === "timeLimit") {
            dispatch(ACTIONS.setLoanTimeLimit(value))
        }
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    return (
        <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} page="financing" title="SME Credit and Business Loans | EVVA - Uniclick" description="With the EVVA Uniclick apply in 2 easy steps. Get a quick credit decision. If approved, funds are transferred in no more than 24 hours. Pay us back over the course of a 3-12 month plan."/>
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            {!cookies.consent &&
            <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler}/>
            }
            <MainSection title="Take your business further" description="<h3>Expand, hire staff, boost cash flow or fund your next step with finance that arrives in days.</h3>" position="left" image="hero-financing.png" actionLabel="Apply now" action={uniclickFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUpUniclick">
                <AppBar pathname={appState.path}/>
            </MainSection>
            <PageSection>
                <div style={{marginTop: "-120px", zIndex: "1"}}>
                    <Card subtitle="We make this possible thanks to Uniclick" description="<p>We have joined forces with Uniclick by Unifin, leader in the financing market, which has funded thousands SMEs in México.</p>" descriptionColor="#363636" image="logo-uniclick.svg" imageWidth="260px" bgColor="#fff" horizontal padding shadow/>
                </div>
            </PageSection>
            <PageSection maxColumns="4" title="We’re here to help you thrive" >
                <Card
                    imageHeight="180px"
                    imageWidth="252px"
                    image="search-icon.svg"
                    description="<p>Simple credits from <br />200 thousand to 2.5 MDP</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
                <Card
                    imageHeight="180px"
                    imageWidth="252px"
                    image="decision-time-icon.svg"
                    description="<p>Get a decision in minutes and without complications</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
                <Card
                    imageHeight="180px"
                    imageWidth="252px"
                    image="total-flexibility-icon.svg"
                    description="<p>Total flexibility, <br />term between 3 and 12 months</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
                <Card
                    imageHeight="180px"
                    imageWidth="252px"
                    image="no-extra-fees-icon.svg"
                    description="<p>No small letters or penalties for advances</p>"
                    textAlign="center"
                    descriptionColor="#363636"
                />
            </PageSection>
            <PageSection maxColumns="4" maxWidth={contentMaxWidth} title="What do you need to get credit for your business?" bgColor="#F9F9F9" >
                <Card
                    imageHeight="100px"
                    image="limited-company-icon.svg"
                    description="<p>Business or Entrepreneur with Business Activity</p>"
                    textAlign="center"
                />
                <Card
                    imageHeight="100px"
                    image="timer-icon.svg"
                    description="<p>Have at least <br />12 months of operation</p>"
                    textAlign="center"
                />
                <Card
                    imageHeight="100px"
                    image="sat-icon.svg"
                    description="<p>Be duly registered in the SAT</p>"
                    textAlign="center"
                />
                <Card
                    imageHeight="100px"
                    image="signature-icon.svg"
                    description="<p>Have your signature for contract signature</p>"
                    textAlign="center"
                />
            </PageSection>
            <PageSection title="Here’s how it works" maxWidth={contentMaxWidth}>
                <Card
                    title="Register your request with EVVA"
                    description="<p>Register your data on our platform. An executive will contact you and follow up your process.</p>"
                    descriptionColor="#363636"
                    image="register-uniclick.png"
                    imageWidth="420px"
                    imageHeight="340px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
                <Card
                    title="Sign your Uniclick contract"
                    description="<p>Give additional documentation and sign your contract.</p>"
                    descriptionColor="#363636"
                    image="sign-uniclick.png"
                    imageWidth="420px"
                    imageHeight="280px"
                    marginTopContent="60px"
                    reverseDirection
                    horizontal
                    padding
                />
                <Card
                    title="Ready! Boost your business now"
                    description="<p>Once your business is authorized, you can receive your credit in your EVVA account or in the account of your choice in no more than 24 hours.</p>"
                    descriptionColor="#363636"
                    image="grow-uniclick.png"
                    imageWidth="420px"
                    imageHeight="290px"
                    marginTopContent="40px"
                    horizontal
                    padding
                />
            </PageSection>
            <PageSection title="Estimate the boost your need for your business" description="Calculations based on Uniclick terms and conditions" descriptionImg="../../logo-uniclick.svg" altDescImg="Uniclick - Crédito Empresarial">
                <LoanCalculator lang={appState.lang} loanValue={appState.loanValue} loanTimeLimit={appState.loanTimeLimit} onChange={rangeHandler} />
            </PageSection>
            <PageSection maxColumns={3} title="Introducing EVVA Marketplace" description="We have a set of products and services for your daily business needs.">
                <Card
                    subtitle="Business account"
                    image="logo.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_evva"
                    link="/en"
                    linkLabel="Learn more"
                    imageShadow
                />
                <Card
                    subtitle="Evva digital rewards"
                    image="logo-evva-wallet.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_evva"
                    link="/en/wallet"
                    linkLabel="Learn more"
                    imageShadow
                />
                <Card
                    subtitle="SAT insights"
                    image="logo-sat-white.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_evva"
                    link="/en/taxes-sat"
                    linkLabel="Learn more"
                    imageShadow
                />

            </PageSection>
            <SecondarySection
                title="Together we grow"
                shortDescription="With a quick application, dedicated account manager and fast decision, you can stay focused on your business while we focus on your loan. Taking your business to the next level has never been easier."
                position="center"
                image="evva-together.jpg"
                actionLabel="Apply now"
                action={uniclickFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUpUniclick"
            />
        </Layout>
    )
};

export default Financing